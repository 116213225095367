import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as CommonApi from "services/CommonApi";
import Helper from "shared/helper";

const fetchVehicleManufacturers = createAsyncThunk('enum/loadVehicleManufacturers', async () => {
    let rslt = await CommonApi.GetVehicleManufacturers();
    if (rslt.status === 100) {
        return rslt.data;
    }
    return [];
});

const fetchVehicleModels = createAsyncThunk('enum/loadVehicleModels', async (id) => {
    let rslt = await CommonApi.GetVehicleModels(id);
    if (rslt.status === 100) {
        return rslt.data;
    }
    return [];
});

const fetchVehicleVariants = createAsyncThunk('enum/loadVehicleVariants', async (id) => {
    let rslt = await CommonApi.GetVehicleVariants(id);
    if (rslt.status === 100) {
        return rslt.data;
    }
    return [];
});

export const enumSlice = createSlice({
    name: 'enum',
    initialState: { vehicleManufacturers: [], vehicleModels: [], vehicleVariants: [] },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVehicleManufacturers.rejected, () => {
            global.Busy(false);
        })
        builder.addCase(fetchVehicleManufacturers.pending, () => {
            global.Busy(true);
        })
        builder.addCase(fetchVehicleManufacturers.fulfilled, (state, action) => {
            let items = Helper.SortBy(action.payload, 'displayname');
            Helper.AddSelectOption(items, true, "value", "text");
            global.Busy(false);
            return { ...state, vehicleManufacturers: items, vehicleModels: [], vehicleVariants: [] };
        })

        builder.addCase(fetchVehicleModels.rejected, () => {
            global.Busy(false);
        })
        builder.addCase(fetchVehicleModels.pending, () => {
            global.Busy(true);
        })
        builder.addCase(fetchVehicleModels.fulfilled, (state, action) => {
            let items = Helper.SortBy(action.payload, 'displayname');
            Helper.AddSelectOption(items, true, "value", "text");
            global.Busy(false);
            return { ...state, vehicleModels: items, vehicleVariants: [] };
        })

        builder.addCase(fetchVehicleVariants.rejected, () => {
            global.Busy(false);
        })
        builder.addCase(fetchVehicleVariants.pending, () => {
            global.Busy(true);
        })
        builder.addCase(fetchVehicleVariants.fulfilled, (state, action) => {
            let items = Helper.SortBy(action.payload, 'displayname');
            Helper.AddSelectOption(items, true, "value", "text");
            global.Busy(false);
            return { ...state, vehicleVariants: items };
        })
    }
});

export { fetchVehicleManufacturers, fetchVehicleModels, fetchVehicleVariants };
export default enumSlice.reducer;